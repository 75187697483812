.ant-divider-inner-text {
  font-size: 1.5rem;
  /* border-coloe */
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.text-primary {
  color: rgb(0, 60, 146);
}

.form-container {
  width: 90%;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
}

.form-grid[data-match='true'] {
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
}

.form-label {
  color: white;
}
.form-label.dark {
  color: rgba(78, 83, 88, 1);
}

.ant-select-selector {
  color: white;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid white !important;
}

.ant-select-selector.dark {
  border-bottom: 1px solid rgba(78, 83, 88, 1) !important;
}

button.add-driver {
  margin-top: 1rem;
  width: 300px;
  height: 70px;
  display: grid;
  background-color: rgba(192, 192, 192, 0.51);
  border-radius: 8px;
  border: none;
}

button.add-driver:hover {
  background-color: rgb(78, 78, 78);
}

button.add-driver div {
  place-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  font-size: 1rem;
}

.ant-radio-button-checked {
  background-color: rgb(0, 60, 146);
}

.ant-radio-button-checked span {
  color: white;
}
